<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div>
            <div class="d-flex align-items-center"> 
                <div class="me-3 icon-box md bg-white rounded-4">
                    <i class="bi bi-bank2 fs-3 text-primary"></i>
                </div>
                <h6 class="m-0 text-dark fw-light me-5" style="font-size: 14px;">
                    Giới thiệu
                </h6>
                <h2 class="mb-1" style="font-size: 26px;">
                    Hệ thống bản đồ số hóa các vùng nuôi và khai thác thủy sản trên địa bàn Thành Phố Hồ Chí Minh
                </h2>
            </div>
             <br>
             <div style="height: 400px; width: 100%;">
                <img src="assets/image/Logo_introduce.jpg" style="height: 100%;width: 100%; object-fit: cover;" alt="Image description">
             </div>
                
        </div>
        
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-GioiThieuDetail" data-bs-toggle="tab"
                                    href="#GioiThieuDetail" role="tab" aria-controls="GioiThieuDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Giới thiệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="GioiThieuDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">                                      
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3>Chủ đầu tư: <b style="color: green;">CHI CỤC THỦY SẢN VÀ KIỂM NGƯ THÀNH PHỐ HỒ CHÍ MINH</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Điện thoại: <a href="#" target="_blank"> <b>028 38 441 384</b></a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5><a href="#" target="_blank">Email: ccts.snn@tphcm.gov.vn</a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Website: <a href="https://cctshcm.gov.vn/" target="_blank">cctshcm.gov.vn</a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Địa chỉ: <a href="#"><b>126GH Phan Đăng Lưu, Phường 3, quận Phú Nhuận, TP.HCM</b></a></h5>
                                        </div>                                        
                                    </div>
                                </div>
                                <hr/>
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">                                      
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3>Đơn vị hỗ trợ kỹ thuật: <b style="color: green;">Công ty Cổ phần Đầu tư
                                                    Digital Kingdom</b></h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Điện thoại: <a href="#" target="_blank"><b>0907 265 583</b></a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Email: <a target="_blank"
                                                    href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@dgk.vn&su=Hi_DGJ&body=https://dgk.vn/&tf=1"><b>info@dgk.vn</b></a>
                                            </h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Website: <a target="_blank" href="https://dgk.vn/"
                                                    title="dgk.vn"><b>dgk.vn</b></a></h5>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h5>Địa chỉ: <a target="_blank" href="https://dgk.vn/lienhe.html"
                                                    title="Liên hệ"><b>Tầng 9, tòa nhà Diamond Plaza, số 34 đường Lê Duẩn, phường Bến Nghé, quận 01, thành phố Hồ Chí Minh</b></a></h5>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>