import { Base } from "./Base.model";

export class Report extends Base { 
  
    KinhDo?: string;
    ViDo?: string;
    DanhMucToChucID?: number;
    DanhMucToChucName?: string;  
    ThongKe001?: number;
    BatDau?: Date;
    KetThuc?: Date;
}


