import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-chuc-nuoi-nhuyen-the001',
  templateUrl: './to-chuc-nuoi-nhuyen-the001.component.html',
  styleUrls: ['./to-chuc-nuoi-nhuyen-the001.component.css']
})
export class ToChucNuoiNhuyenThe001Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
