import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-huong-dan',
  templateUrl: './huong-dan.component.html',
  styleUrls: ['./huong-dan.component.css']
})
export class HuongDanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
