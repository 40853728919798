import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-chuc-vung-trong-detail',
  templateUrl: './to-chuc-vung-trong-detail.component.html',
  styleUrls: ['./to-chuc-vung-trong-detail.component.css']
})
export class ToChucVungTrongDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
