import { Component, OnInit } from '@angular/core';
import { DownloadService } from 'src/app/shared/Download.service';
import { ToChucService } from 'src/app/shared/ToChuc.service';


@Component({
  selector: 'app-download-geom-system',
  template: '',
})
export class DownloadGeomSystemComponent implements OnInit {

  constructor(
    public DownloadService: DownloadService,
    public ToChucService: ToChucService,
  ) { }

  ngOnInit(): void {
    this.ToChucDownload();
  }
  ToChucDownload() {
    this.ToChucService.IsShowLoading = true;
    this.DownloadService.BaseParameter.ParentID = this.ToChucService.BaseParameter.ParentID;
    this.DownloadService.ExportToChucGeomToExcelAsync().subscribe(
      res => {
        window.open(res.toString(), "_blank");
      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
}
