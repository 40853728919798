<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-Upload" data-bs-toggle="tab" href="#Upload"
                                    role="tab" aria-controls="Upload" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Upload</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="Upload" role="tabpanel">     
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <h3>Vùng trồng</h3>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{URLExcel}}" title="Tải về">Tải về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" accept=".xlsx"
                                                (change)="ChangeFile($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!IsFile" class="btn btn-info" (click)="Upload()"
                                            style="width: 100%;">Upload</button>
                                    </div>                                                                 
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="UploadService.IsShowLoading"></app-loading>