import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-external-redirect',
  template: '',
})
export class ExternalRedirectComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
    const currentUrl = this.router.url;

    if (currentUrl === '/TraCuu001') {
      window.open('https://csdl.tongcucthuysan.gov.vn/cms.nc/tracuu/cososx', '_blank');
    } else if (currentUrl === '/TraCuu002') {
      window.open('https://csdl.tongcucthuysan.gov.vn/cms.nc/tracuu/sanpham', '_blank');
    }
  }

}
