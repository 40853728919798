<div class="app-hero-header d-flex align-items-center">
  <div class="d-flex align-items-center">
    <div class="me-3 icon-box md bg-white rounded-4">
      <i class="bi bi-bar-chart fs-3 text-primary"></i>
    </div>
    <div>
      <h2 class="mb-1">Báo Cáo Thống Kê</h2>
    </div>
  </div>
  <div class="ms-auto d-lg-flex d-none flex-row">
    <div class="d-flex flex-row gap-2"></div>
  </div>
</div>
<div class="app-body">
  <div class="row gx-4 justify-content-center align-items-center">
    <h5 class="m-0 text-primary">A. Số liệu tổng hợp</h5>
    <br />
    <div class="container">
      <div class="row gx-4 justify-content-center align-items-center mb-4">
        <div class="col-lg-2 col-sm-6 col-12">
          <label for="fromDate" class="form-label">Từ Ngày</label>
          <input
            type="date"
            id="fromDate"
            [(ngModel)]="ReportService.FormData.BatDau"
            class="form-control"
            name="fromDate"
          />
        </div>
        <div class="col-lg-2 col-sm-6 col-12">
          <label for="toDate" class="form-label">Tới Ngày</label>
          <input
            type="date"
            id="toDate"
            [(ngModel)]="this.ReportService.FormData.KetThuc"
            class="form-control"
            name="toDate"
          />
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <label *ngIf="!NotificationService.IsMobile" class="form-label"
            >Quận huyện [{{
              ToChucService.BaseParameter.DanhMucQuanHuyenID
            }}]</label
          >
          <select
            class="form-select"
            name="DanhMucQuanHuyenID"
            [(ngModel)]="ToChucService.BaseParameter.DanhMucQuanHuyenID"
            (change)="DanhMucXaPhuongSearch()"
          >
            <option value="0">Tất cả</option>
            <option
              *ngFor="let item of DanhMucQuanHuyenService.List"
              [value]="item.ID"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
          <label *ngIf="!NotificationService.IsMobile" class="form-label"
            >Xã phường [{{
              ToChucService.BaseParameter.DanhMucXaPhuongID
            }}]</label
          >
          <select
            class="form-select"
            name="DanhMucXaPhuongID"
            [(ngModel)]="ToChucService.BaseParameter.DanhMucXaPhuongID"
          >
            <option value="0">Tất cả</option>
            <option
              *ngFor="let item of DanhMucXaPhuongService.List"
              [value]="item.ID"
            >
              {{ item.Name }}
            </option>
          </select>
        </div>
        <div class="col-lg-1 col-sm-6 col-12">
          <label for="toDate" class="form-label">Cập Nhật</label>
          <input
            type="button"
            id="toDate"
            class="form-control"
            name="toDate"
            value="Cập Nhật"
            (click)="updateChartFromDateToDateGeneral()"
          />
        </div>
      </div>
      <!-- Hàng đầu tiên (4 thẻ) -->
      <div
        class="row gx-4 justify-content-center align-items-center"
        *ngIf="ReportService.list1002?.length > 0"
      >
        <div
          class="col-lg-2 col-sm-12 col-12"
          *ngFor="let item of ReportService.list1002.slice(0, 4)"
        >
          <div class="card my-2">
            <div class="card-header">
              <h5 class="card-title text-dark">{{ item.ParentName }}</h5>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-dark">
                  <h2 class="display-6 mb-2 fw-semibold">
                    {{ item.ThongKe001 | number : "1.0-0" }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Hàng thứ hai (4 thẻ) -->
      <div class="row gx-4 justify-content-center align-items-center mt-3">
        <div
          class="col-lg-2 col-sm-12 col-12"
          *ngFor="let item of ReportService.list1002.slice(4, 8)"
        >
          <div class="card my-2">
            <div class="card-header">
              <h5 class="card-title text-dark">{{ item.ParentName }}</h5>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div class="text-dark">
                  <h2 class="display-6 mb-2 fw-semibold">
                    {{ item.ThongKe001 | number : "1.0-0" }}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
  <h5 class="m-0 text-primary">B. Biểu đồ tổng hợp</h5>
  <br />
  <div class="row gx-4 justify-content-center align-items-center mb-4">
    <div class="col-lg-2 col-sm-6 col-12">
      <label for="fromDate" class="form-label">Từ Ngày</label>
      <input
        type="date"
        id="fromDate"
        [(ngModel)]="ReportService.FormData.BatDau"
        class="form-control"
        name="fromDate"
      />
    </div>
    <div class="col-lg-2 col-sm-6 col-12">
      <label for="toDate" class="form-label">Tới Ngày</label>
      <input
        type="date"
        id="toDate"
        [(ngModel)]="ReportService.FormData.KetThuc"
        class="form-control"
        name="toDate"
      />
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
      <label *ngIf="!NotificationService.IsMobile" class="form-label"
        >Quận huyện [{{
          ToChucService.BaseParameter.DanhMucQuanHuyenID
        }}]</label
      >
      <select
        class="form-select"
        name="DanhMucQuanHuyenID"
        [(ngModel)]="ToChucService.BaseParameter.DanhMucQuanHuyenID"
        (change)="DanhMucXaPhuongSearch()"
      >
        <option value="0">Tất cả</option>
        <option
          *ngFor="let item of DanhMucQuanHuyenService.List"
          [value]="item.ID"
        >
          {{ item.Name }}
        </option>
      </select>
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
      <label *ngIf="!NotificationService.IsMobile" class="form-label"
        >Xã phường [{{
          ToChucService.BaseParameter.DanhMucXaPhuongID
        }}]</label
      >
      <select
        class="form-select"
        name="DanhMucXaPhuongID"
        [(ngModel)]="ToChucService.BaseParameter.DanhMucXaPhuongID"
      >
        <option value="0">Tất cả</option>
        <option
          *ngFor="let item of DanhMucXaPhuongService.List"
          [value]="item.ID"
        >
          {{ item.Name }}
        </option>
      </select>
    </div>
    <div class="col-lg-1 col-sm-6 col-12">
      <label for="toDate" class="form-label">Cập Nhật</label>
      <input
        type="button"
        id="toDate"
        class="form-control"
        name="toDate"
        value="Cập Nhật"
        (click)="updateChartFromDateToDate()"
      />
    </div>
  </div>
  <div class="row gx-4">
    <!-- Thêm danh sách button -->
    <div class="col-12 text-center mb-4">
      <button
        *ngFor="let item of fixedDanhMucToChucNames"
        class="btn btn-primary mx-2"
        (click)="onFilterChart(item)"
      >
        {{ item }}
      </button>
    </div>

    <div class="col-lg-6 col-sm-12 col-12">
      <h3 class="text-center" style="text-transform: uppercase">
        Dữ liệu tổng hợp Biểu Đồ Cột
      </h3>
      <canvas
        style="font-size: 30px"
        baseChart
        [datasets]="ChartDataReport1002"
        [labels]="ChartLabelsReport1002"
        [chartType]="ChartTypeReport1002"
        [colors]="ChartColorsReport1002"
        [options]="ChartOptionsReport1002"
      >
      </canvas>
    </div>
    <div class="col-lg-6 col-sm-12 col-12">
      <h3 class="text-center" style="text-transform: uppercase">
        Dữ liệu tổng hợp Biểu Đồ Tròn
      </h3>
      <canvas
        style="font-size: 30px"
        baseChart
        [datasets]="ChartDataReport1002SoLuongKhaoSat"
        [labels]="ChartLabelsReport1002SoLuongKhaoSat"
        [chartType]="ChartTypeReport1002SoLuongKhaoSat"
        [colors]="ChartColorsReport1002SoLuongKhaoSat"
        [options]="ChartOptionsReport1002SoLuongKhaoSat"
      >
      </canvas>
    </div>
  </div>

  <br />
</div>
<br />
<app-loading *ngIf="isShowLoading"></app-loading>
