import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';

import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVienChucNang } from 'src/app/shared/ThanhVienChucNang.model';
import { ThanhVienChucNangService } from 'src/app/shared/ThanhVienChucNang.service';

@Component({
  selector: 'app-thanh-vien-chuc-nang-detail',
  templateUrl: './thanh-vien-chuc-nang-detail.component.html',
  styleUrls: ['./thanh-vien-chuc-nang-detail.component.css']
})
export class ThanhVienChucNangDetailComponent implements OnInit {

  @ViewChild('ThanhVienChucNangSort') ThanhVienChucNangSort: MatSort;
  @ViewChild('ThanhVienChucNangPaginator') ThanhVienChucNangPaginator: MatPaginator;

  constructor(
    public dialogRef: MatDialogRef<ThanhVienChucNangDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucChucNangService: DanhMucChucNangService,

    public DanhMucThanhVienService: DanhMucThanhVienService,   

    public ThanhVienChucNangService: ThanhVienChucNangService,
  ) {
  }

  ngOnInit(): void {
    this.DanhMucThanhVienSearch();    
    this.ThanhVienChucNangSearch();
    
  }
  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync(this.ThanhVienChucNangService);
  }
  ThanhVienChucNangSearch() {
    if (this.ThanhVienChucNangService.BaseParameter.SearchString.length > 0) {
      this.ThanhVienChucNangService.DataSource.filter = this.ThanhVienChucNangService.BaseParameter.SearchString.toLowerCase();
    }
    else {
      this.ThanhVienChucNangGetToList();
    }
  }
  ThanhVienChucNangGetToList() {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienChucNangService.BaseParameter.DanhMucChucNangID = this.DanhMucChucNangService.FormData.ID;
    this.ThanhVienChucNangService.GetByDanhMucChucNangIDAndEmptyToListAsync().subscribe(
      res => {
        this.ThanhVienChucNangService.List = (res as ThanhVienChucNang[]);
        this.ThanhVienChucNangService.DataSource = new MatTableDataSource(this.ThanhVienChucNangService.List);
        this.ThanhVienChucNangService.DataSource.sort = this.ThanhVienChucNangSort;
        this.ThanhVienChucNangService.DataSource.paginator = this.ThanhVienChucNangPaginator;
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }

  

  ThanhVienChucNangSave(element: ThanhVienChucNang) {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienChucNangService.FormData = element;
    this.ThanhVienChucNangService.FormData.DanhMucChucNangID = this.DanhMucChucNangService.FormData.ID;
    this.ThanhVienChucNangService.SaveAsync().subscribe(
      res => {
        this.ThanhVienChucNangSearch();        
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }
  ThanhVienChucNangDelete(element: ThanhVienChucNang) {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienChucNangService.BaseParameter.ID = element.ID;
    this.ThanhVienChucNangService.RemoveAsync().subscribe(
      res => {
        this.ThanhVienChucNangSearch();        
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }

}