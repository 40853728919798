import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-external-redirect',
  template: '',
})
export class ApiSystemComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    const currentUrl = this.router.url;

    if (currentUrl === '/QuanLyAPI') {
      window.open('https://api.bandothuysan.tphcm.vungtrong.vn/swagger/index.html', '_blank');
    } 
  }

}
