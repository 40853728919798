import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thong-ke000',
  templateUrl: './thong-ke000.component.html',
  styleUrls: ['./thong-ke000.component.css']
})
export class ThongKe000Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
