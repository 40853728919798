import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucVungNuoiTinhTrang } from 'src/app/shared/ToChucVungNuoiTinhTrang.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucVungNuoiTinhTrangService extends BaseService {
    List: ToChucVungNuoiTinhTrang[] | undefined;
    ListFilter: ToChucVungNuoiTinhTrang[] | undefined;
    FormData!: ToChucVungNuoiTinhTrang; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.Controller = "ToChucVungNuoiTinhTrang";
    }
}

