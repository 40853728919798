<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-lock fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Đổi mật khẩu</h2>
            <h6 class="m-0 text-dark fw-light">
                Tài khoản
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()" class="btn btn-info"><i
                    class="bi bi-sd-card"></i> Lưu thay đổi</a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab"
                                    href="#ThanhVienDetail" role="tab" aria-controls="ThanhVienDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">                               
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.FormDataLogin.TaiKhoan"
                                                disabled placeholder="Tài khoản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mật khẩu</label>
                                            <div class="input-group">
                                                <input name="MatKhau" [(ngModel)]="ThanhVienService.FormDataLogin.MatKhau" placeholder="Mật khẩu"
                                                    type="password" class="form-control" [type]="MatKhauIsActive ? 'password' : 'text'">
                                                <button class="btn btn-outline-secondary" type="button" (click)="MatKhauChangeType()">
                                                    <i class="bi bi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                      
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">  
                                        <div class="row">
                                            <div class="col-lg-8 col-sm-8 col-12">
                                                <label for="imageUpload" class="form-label">Chọn hình ảnh:</label>
                                                <input type="file" accept="image/*" id="imageUpload" class="form-control"
                                                    (change)="onFileSelected($event)" />
                                            </div> 
                                            <div  class="col-lg-4 col-sm-4 col-12">
                                                <img  [src]="previewUrl || 'assets/image/user_default.png'" 
                                                alt="Hình ảnh đã chọn" 
                                                style="margin-top: 10px; max-width: 100%;width: 50%;border-radius: 8px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);"  />  
                                            </div>
                                        </div>                 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>