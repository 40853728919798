import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DanhMucXaPhuong } from '../shared/DanhMucXaPhuong.model';
import { DanhMucQuanHuyen } from '../shared/DanhMucQuanHuyen.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { ReportService } from 'src/app/shared/Report.service';
import { Report } from 'src/app/shared/Report.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';


@Component({
  selector: 'app-dashboard000',
  templateUrl: './dashboard000.component.html',
  styleUrls: ['./dashboard000.component.css']
})
export class Dashboard000Component implements OnInit {

  isShowLoading: boolean = false;
  domainName = environment.DomainDestination;
  searchString: string = environment.InitializationString;
  DanhMucTinhThanhID: number = environment.DanhMucTinhThanhID;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  fixedDanhMucToChucNames: string[] = [
    'Tất cả',
    'Hộ nuôi',
    'Điểm quan trắc',
    'Hợp tác xã',
    'Phương tiện khai thác',
    'Cơ sở sản xuất Giống',
    'Cửa hàng',
    'Lồng bè',
    'Nuôi nhuyễn thể',
    'Hộ nuôi cá cảnh'
  ];
  
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,


    public ReportService: ReportService,
    public ToChucService: ToChucService,
  ) {

    this.ReportService.list1002 = [];
    this.ReportService.FormData = new Report();
   }

  ngOnInit(): void {
    const defaultStartDate = new Date(); 
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 12);
    const defaultEndDate = new Date();
    this.ReportService.FormData.BatDau ?? defaultStartDate;
    this.ReportService.FormData.KetThuc ?? defaultEndDate;
    this.Report000003();
    this.Report000012ToListAsync();
    this.DanhMucQuanHuyenSearch();
  }

  ngAfterView():void{
    this.Report000003();
    this.Report000012ToListAsync();
  }


  Report000003() {
    debugger
    this.isShowLoading = true;
    const defaultStartDate = new Date(); 
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 12);
    const defaultEndDate = new Date(); 
    this.ReportService.BaseParameter.BatDau = this.ReportService.FormData.BatDau ?? defaultStartDate;
    this.ReportService.BaseParameter.KetThuc = this.ReportService.FormData.KetThuc ?? defaultEndDate;
    this.ReportService.BaseParameter.DanhMucQuanHuyenID = this.ToChucService.BaseParameter.DanhMucQuanHuyenID ?? 0
    this.ReportService.BaseParameter.DanhMucXaPhuongID = this.ToChucService.BaseParameter.DanhMucXaPhuongID ?? 0
    this.ReportService.Report00003ToListAsync().subscribe(
      res => {
         // Kiểm tra nếu dữ liệu trả về có chứa giá trị hợp lệ
      if (res && Array.isArray(res)) {
        this.ReportService.list1002 = (res as Report[]);
      } else {
        this.ReportService.list1002 = [];
      }
      this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Report000012ToListAsync() {
    this.isShowLoading = true;
    const defaultStartDate = new Date(); 
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 12);
    const defaultEndDate = new Date(); 
    this.ReportService.BaseParameter.BatDau = this.ReportService.FormData.BatDau ?? defaultStartDate;
    this.ReportService.BaseParameter.KetThuc = this.ReportService.FormData.KetThuc ?? defaultEndDate;
    this.ReportService.BaseParameter.ParentID = this.ToChucService.BaseParameter.DanhMucQuanHuyenID;
    this.ReportService.BaseParameter.DanhMucXaPhuongID = this.ToChucService.BaseParameter.DanhMucXaPhuongID ?? 0
    this.ReportService.Report000012ToListAsync().subscribe(
      res => {
        this.ReportService.List000012 = (res as Report[]);
        const groupedData = {};

        // Nhóm và cộng dồn ThongKe001 theo ParentName
        this.ReportService.List000012.forEach(item => {
          if (!groupedData[item.ParentName]) {
            groupedData[item.ParentName] = 0; // Nếu chưa có, khởi tạo bằng 0
          }
          groupedData[item.ParentName] += item.ThongKe001; // Cộng dồn ThongKe001 cho mỗi ParentName
        });

        // Tạo nhãn và dữ liệu từ dữ liệu đã nhóm
        const labels = Object.keys(groupedData); // Danh sách ParentName
        const data = Object.values(groupedData); // Danh sách tổng ThongKe001

        // Cập nhật dữ liệu biểu đồ
        this.ChartLabelsReport1002 = labels;
        this.ChartDataReport1002 = [{ data, label: 'Số lượng' }];
        this.ChartLabelsReport1002SoLuongKhaoSat = labels;
        this.ChartDataReport1002SoLuongKhaoSat = [{ data, label: 'Số lượng' }];
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  updateChartFromDateToDateGeneral(){
    this.isShowLoading = true;
    const defaultStartDate = new Date(); 
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 12);
    const defaultEndDate = new Date(); 
    this.ReportService.BaseParameter.BatDau = this.ReportService.FormData.BatDau ?? defaultStartDate;
    this.ReportService.BaseParameter.KetThuc = this.ReportService.FormData.KetThuc ?? defaultEndDate;
    this.Report000003();
  }

  updateChartFromDateToDate(){
  this.isShowLoading = true;
  const defaultStartDate = new Date(); 
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 12);
  const defaultEndDate = new Date(); 
  this.ReportService.BaseParameter.BatDau = this.ReportService.FormData.BatDau ?? defaultStartDate;
  this.ReportService.BaseParameter.KetThuc = this.ReportService.FormData.KetThuc ?? defaultEndDate;
    this.Report000012ToListAsync();
  }

  onFilterChart(selectedName: string) {
    if (selectedName != "Tất cả") {
      const filteredData = this.ReportService.List000012.filter(item => item.DanhMucToChucName === selectedName);
      if (filteredData.length > 0) {
        // Group data by ParentName and sum ThongKe001 values
        const groupedData = filteredData.reduce((acc, item) => {
          if (!acc[item.ParentName]) {
            acc[item.ParentName] = 0; // Initialize sum for this group
          }
          acc[item.ParentName] += item.ThongKe001; // Sum ThongKe001
          return acc;
        }, {} as { [key: string]: number });

        // Extract labels (ParentName) and corresponding data
        const labels = Object.keys(groupedData);
        const data = Object.values(groupedData);

        // Update chart labels and data
        this.ChartLabelsReport1002 = labels;
        this.ChartDataReport1002 = [{ data, label: selectedName }];
        this.ChartLabelsReport1002SoLuongKhaoSat = labels;
        this.ChartDataReport1002SoLuongKhaoSat = [{ data, label: selectedName }];
      }
    } else {
      const groupedData = {};
      // Nhóm và cộng dồn ThongKe001 theo ParentName
      this.ReportService.List000012.forEach(item => {
        if (!groupedData[item.ParentName]) {
          groupedData[item.ParentName] = 0; // Nếu chưa có, khởi tạo bằng 0
        }
        groupedData[item.ParentName] += item.ThongKe001; // Cộng dồn ThongKe001 cho mỗi ParentName
      });

      // Tạo nhãn và dữ liệu từ dữ liệu đã nhóm
      const labels = Object.keys(groupedData); // Danh sách ParentName
      const data = Object.values(groupedData); // Danh sách tổng ThongKe001

      // Cập nhật dữ liệu biểu đồ
      this.ChartLabelsReport1002 = labels;
      this.ChartDataReport1002 = [{ data, label: 'Số lượng' }];
      this.ChartLabelsReport1002SoLuongKhaoSat = labels;
      this.ChartDataReport1002SoLuongKhaoSat = [{ data, label: 'Số lượng' }];
    }

  }


// chart bar
  public ChartOptionsReport1002: ChartOptions = {
    responsive: true,
    animation: {
      duration: 1,
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        ctx.textAlign = 'center';
        ctx.fillStyle = "rgba(0, 0, 0, 1)";
        ctx.textBaseline = 'bottom';
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 5);

          });
        });
      }
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    },
    scales: {
      yAxes: [
        {
          id: 'A',
          position: 'left',
        }, {
          id: 'B',
          position: 'right',
        }
      ]
    },
  };
  public ChartColorsReport1002: Color[] = [
  ]
  public ChartLabelsReport1002: Label[] = [];
  public ChartTypeReport1002: ChartType = 'bar';
  public ChartLegendReport1002 = true;
  public ChartPluginsReport1002 = [];

  public ChartDataReport1002: ChartDataSets[] = [
  ];
  // chart doughnut
  public ChartOptionsReport1002SoLuongKhaoSat: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReport1002SoLuongKhaoSat: Color[] = [
  ]
  public ChartLabelsReport1002SoLuongKhaoSat: Label[] = [];
  public ChartTypeReport1002SoLuongKhaoSat: ChartType = 'doughnut';
  public ChartLegendReport1002SoLuongKhaoSat = true;
  public ChartPluginsReport1002SoLuongKhaoSat = [];

  public ChartDataReport1002SoLuongKhaoSat: ChartDataSets[] = [
  ];


  public ChartOptionsReport1002DienTichKhaoSat: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReport1002DienTichKhaoSat: Color[] = [
  ]
  public ChartLabelsReport1002DienTichKhaoSat: Label[] = [];
  public ChartTypeReport1002DienTichKhaoSat: ChartType = 'doughnut';
  public ChartLegendReport1002DienTichKhaoSat = true;
  public ChartPluginsReport1002DienTichKhaoSat = [];

  public ChartDataReport1002DienTichKhaoSat: ChartDataSets[] = [
  ];


  Search() {
    alert("Đang phát triển");
  }

  DanhMucQuanHuyenSearch() {
    if (this.DanhMucQuanHuyenService.List) {
      if (this.DanhMucQuanHuyenService.List.length == 0) {
        this.ToChucService.IsShowLoading = true;
        this.DanhMucQuanHuyenService.GetAllToListAsync().subscribe(
          res => {
            this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
            this.DanhMucQuanHuyenService.ListFilter = this.DanhMucQuanHuyenService.List;
            this.DanhMucXaPhuongSearch();
          },
          err => {
          },
          () => {
            this.ToChucService.IsShowLoading = false;
          }
        );
      }
    }
  }
  DanhMucXaPhuongSearch() {
    this.ToChucService.IsShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.ToChucService.BaseParameter.DanhMucQuanHuyenID;
    if (this.ToChucService.BaseParameter.DanhMucQuanHuyenID == 0) {
      this.DanhMucXaPhuongService.BaseParameter.ParentID = 0;
      this.ToChucService.BaseParameter.DanhMucXaPhuongID = 0;
    }
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.DanhMucXaPhuongService.List.length > 0) {
          this.ToChucService.BaseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongService.List[0].ID;
        }
      },
      err => {
      },
      () => {
        this.ToChucService.IsShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.List = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.List;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.BaseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.List = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.List;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.BaseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.List = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.List;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  public ChartOptionsReportThoiTiet0001NhietDo: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001NhietDo: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001NhietDo: Label[] = [];
  public ChartTypeReportThoiTiet0001NhietDo: ChartType = 'line';
  public ChartLegendReportThoiTiet0001NhietDo = true;
  public ChartPluginsReportThoiTiet0001NhietDo = [];

  public ChartDataReportThoiTiet0001NhietDo: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0002NhietDo: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0002NhietDo: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0002NhietDo: Label[] = [];
  public ChartTypeReportThoiTiet0002NhietDo: ChartType = 'line';
  public ChartLegendReportThoiTiet0002NhietDo = true;
  public ChartPluginsReportThoiTiet0002NhietDo = [];

  public ChartDataReportThoiTiet0002NhietDo: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0001DoAm: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0001DoAm: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0001DoAm: Label[] = [];
  public ChartTypeReportThoiTiet0001DoAm: ChartType = 'line';
  public ChartLegendReportThoiTiet0001DoAm = true;
  public ChartPluginsReportThoiTiet0001DoAm = [];

  public ChartDataReportThoiTiet0001DoAm: ChartDataSets[] = [
  ];

  public ChartOptionsReportThoiTiet0002DoAm: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      position: 'right'
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + '';
        }
      }
    }
  };
  public ChartColorsReportThoiTiet0002DoAm: Color[] = [
  ]
  public ChartLabelsReportThoiTiet0002DoAm: Label[] = [];
  public ChartTypeReportThoiTiet0002DoAm: ChartType = 'line';
  public ChartLegendReportThoiTiet0002DoAm = true;
  public ChartPluginsReportThoiTiet0002DoAm = [];

  public ChartDataReportThoiTiet0002DoAm: ChartDataSets[] = [
  ];

}
