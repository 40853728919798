export class ThongSoMoiTruong {
    ID?: number;
    ToChucID?: number;
    DanhMucChiTieuMoiTruongID?: number;
    DanhMucChiTieuMoiTruongName?: string;
    SoLieu?: number;
    DonViTinh:string;
    NgayGhiNhan:Date;

    constructor(ID:number = 0,ToChucID: number = 0, DanhMucChiTieuMoiTruongID: number = 0, DanhMucChiTieuMoiTruongName: string = '',SoLieu : number = 0,DonViTinh : string ='',NgayGhiNhan = new Date()) {
        this.ID = ID;
        this.ToChucID = ToChucID;
        this.DanhMucChiTieuMoiTruongID = DanhMucChiTieuMoiTruongID;
        this.DanhMucChiTieuMoiTruongName = DanhMucChiTieuMoiTruongName;
        this.SoLieu = SoLieu;
        this.DonViTinh = DonViTinh;
        this.NgayGhiNhan = NgayGhiNhan;
    }
}
