import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thong-bao',
  templateUrl: './thong-bao.component.html',
  styleUrls: ['./thong-bao.component.css']
})
export class ThongBaoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
